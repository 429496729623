import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import { useActivityLog, usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store";
import moment from "moment";
import { getHolidaysInStore, getPublicHolidaysInStore } from "../../Stores";
import { getHourListApi, getIntegrationDetailsApi, updateManageStatusApi, updateStoreHourApi } from "../../OnlineOrdering/api/onlineOrderingApi";
import { uploadPushMenuDoordash } from "../api";

export const useDoordash = ({ storeID }) => {

  const doorDashID = 104
  const isEditable = usePermission("ubereats-modify");
  const { appState } = useContext(AppContext);
  const { onlineOrdering: currentTab } = appState.currentTabs;
  const { addActivityLog } = useActivityLog();
  const { setCurentTab } = useTabs();
  const [state, setState] = useImmer({
    initialLoad: true,
    isBusy: true,
    isSaveButton: false,
    isSaveButtonBusy: false,
    details: null,
    isListBusy: true,
    hourList: [],
    isHourSaveButton: true,
    isHourBusy: false,
    manageModal: false,
    manageData: null,
    isManageBusy: false,
    holidayData: {
      holidayList: [],
      publicHolidayList: [],
    }
  });

  useEffect(() => {
    getIntegrationDetails()
    getHourList()
    getHolidays()
    getPublicHolidays()
  }, [])

  const getIntegrationDetails = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getIntegrationDetailsApi(doorDashID, storeID);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.details = res.data;
          draft.manageData = {
            external_manage_status: res.data.external_manage_status,
            open_at: res.data.open_at,
            close_at: res.data.close_at,
            close_message: res.data.close_message,
          }
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  }

  const getHourList = async () => {
    try {
      setState((draft) => {
        draft.isListBusy = true;
      });
      const res = await getHourListApi(doorDashID, storeID);
      if (res.success) {
        setState((draft) => {
          draft.isListBusy = false;
          draft.hourList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }

  const handleInputChange = (event, type, index) => {
    const { value, name } = event?.target;
    setState((draft) => {
      draft.hourList = [
        ...draft.hourList.map((data, i) => {
          return i === index ? { ...data, [name]: value } : data;
        }),
      ];
      draft.isHourSaveButton = false;
    });
  };

  const onUpdateWeekdays = async () => {
    try {
      setState((draft) => {
        draft.isHourBusy = true;
        draft.isHourSaveButton = true;
      });
      let payload = {
        store_hours: state.hourList.map((v) => {
          return {
            ...v,
            closing_time: moment(v.closing_time, "HH:mm:ss").format("HH:mm"),
            opening_time: moment(v.opening_time, "HH:mm:ss").format("HH:mm")
          }
        })
      };
      const res = await updateStoreHourApi(doorDashID, storeID, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isHourBusy = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isHourSaveButton = false;
        draft.isHourBusy = false;
      });
    }
  };

  const handleManageModal = (state) => {
    setState((draft) => {
      draft.manageModal = state;
    });
  }

  const handleManageInput = (event) => {
    const { value, name } = event?.target;
    setState((draft) => {
      draft.manageData[name] = value;
    });
  }

  const onUpdateMangeStatus = async () => {
    try {
      setState((draft) => {
        draft.isManageBusy = true;
      });
      let payload = {
        ...state.manageData,
        close_at: moment(state.manageData.close_at, "HH:mm:ss").format("HH:mm"),
        open_at: moment(state.manageData.open_at, "HH:mm:ss").format("HH:mm")
      }
      const res = await updateManageStatusApi(doorDashID, storeID, payload);
      if (res.success) {
        successMessage(res.message);
        handleManageModal(false)
        getIntegrationDetails()
        setState((draft) => {
          draft.isManageBusy = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isManageBusy = false;
      });
    }
  };
  const getHolidays = async () => {
    try {
      const res = await getHolidaysInStore(storeID);
      if (res.success) {
        setState((draft) => {
          draft.holidayData.holidayList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }

  const getPublicHolidays = async () => {
    try {
      const res = await getPublicHolidaysInStore(storeID);
      if (res.success) {
        setState((draft) => {
          draft.holidayData.publicHolidayList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }


  const handlePushMenu = async () => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await uploadPushMenuDoordash(storeID);
      activityLog(storeID);
      infoMessage(res.data.message);
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };

  const activityLog = (id, data) => {
    const filteredNewData = data?.find((item) => item.store_id === id);

    addActivityLog({
      templateId: 5,
      parameters: { StoreName: filteredNewData?.store_name },
      uniqueParameter: id,
      data: { newLogData: filteredNewData, oldLogData: {} },
    });
  };

  return {
    state,
    currentTab,
    setCurentTab,
    handleInputChange,
    isEditable,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
    onUpdateMangeStatus,
    handlePushMenu,
  };
};
