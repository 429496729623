import * as React from "react";
import { Stack, Typography, Divider, TextField, Box } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

export const SalesListComponent = (prop) => {
  const { state, onSalesChannelChange, item, index, validator } = prop;
  const { PriceLevels } = state;


  return (
    <>
      <>
        <Divider />
        <Stack p="24px 24px 16px" direction="row">
          <Typography variant="body1" lineHeight="19px" minWidth="160px">
            {item.channel}
          </Typography>

          <Stack direction="row" gap="24px" width="100%">
            <InputControl
              type="dropdown"
              disableClearable
              getOptionLabel={(option) => option.name}
              value={
                (PriceLevels.length > 0 &&
                  item.price_level_id &&
                  PriceLevels.find((c) => c.id === item.price_level_id)) ||
                null
              }
              // disabled={item.status === 0}
              options={PriceLevels}
              onChange={(e, newValue) => {
                onSalesChannelChange(item, "price-level", newValue.id, index);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Price Level"
                  required
                  error={validator.current.message(
                    `Price Level ${` `.repeat(index)}`,
                    item.price_level_id,
                    "required"
                  )}
                  helperText={validator.current.message(
                    `Price Level ${` `.repeat(index)}`,
                    item.price_level_id,
                    "required"
                  )}
                />
              )}
            />

            <Stack
              sx={{
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
                minWidth: "fit-content",
              }}>
              <Box sx={{
                width: ".5px",
                height: "32px",
                bgcolor: "#D9D9D9",
                mr: "10px"
              }} />
              <Typography
                variant="subtitle2"
                fontWeight={600}
              >
                Store Status
              </Typography>
              <div>
                <InputControl
                  id={index}
                  type="toggle-switch"
                  value={item.status}
                  onChange={(e) => onSalesChannelChange(item, "status", null, index)}
                />
              </div>
            </Stack>
          </Stack>
        </Stack>
      </>
    </>
  );
};
