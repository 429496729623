import * as React from "react";
import { Stack, Card, Typography, Button } from "@mui/material";
import { useStoreSaleChannels } from "../hooks";
import { Loader } from "../../../shared";
import { SalesListComponent } from ".";
import SimpleReactValidator from "simple-react-validator";

export const SalesChannels = ({ storeName }) => {
  const { state, onSalesChannelChange, onSaveSalesChannel } = useStoreSaleChannels({
    isPriceLevel: true,
    storeName,
  });

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onSaveSalesChannel();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  if (state.isBusy) {
    return <Loader />;
  }
  return (
    <>
      <Card sx={{ mb: "24px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="83px"
          p="32px 24px"
        >
          <Stack
            sx={{
              gap: "4px"
            }}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              lineHeight="19px"
              minWidth="160px"
            >
              Sales Channels
            </Typography>
            <Typography variant="subtitle2" color="#636363">
              When the Store Status is inactive, the store will be marked as "Closed Indefinitely."
            </Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Stack>
        {state.data.map((item, index) => {
          return (
            <SalesListComponent
              state={state}
              onSalesChannelChange={onSalesChannelChange}
              item={item}
              index={index}
              validator={validator}
            />
          );
        })}
      </Card>
    </>
  );
};
