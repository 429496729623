import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import qs from "qs";
import { usePermission, useTabs } from "../../../shared";
import { editStoreStatus, getHourListApi, getOnlineOrdering, getIntegrationDetailsApi, updateManageStatusApi, updateStoreHourApi } from "../api/onlineOrderingApi";
import { AppContext } from "../../../store";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";
import moment from "moment";
import { getHolidaysInStore, getPublicHolidaysInStore } from "../../Stores";

export const useOnlineOrdering = ({ isList, storeID }) => {

  const onlineOrderingID = 101
  const isEditable = usePermission("online-ordering-modify");
  const { appState } = useContext(AppContext);
  const { onlineOrdering: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const [state, setState] = useImmer({
    initialLoad: true,
    isBusy: true,
    isSaveButtonBusy: false,
    details: null,
    isListBusy: true,
    hourList: [],
    isHourSaveButton: true,
    isHourBusy: false,
    manageModal: false,
    manageData: null,
    isManageBusy: false,
    holidayData: {
      holidayList: [],
      publicHolidayList: [],
    }
  });

  useEffect(() => {
    getOnlineOrderingDetails()
    getHourList()
    getHolidays()
    getPublicHolidays()
  }, [])

  const getOnlineOrderingDetails = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getIntegrationDetailsApi(onlineOrderingID, storeID);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.details = res.data;
          draft.manageData = {
            external_manage_status: res.data.external_manage_status,
            open_at: res.data.open_at,
            close_at: res.data.close_at,
            close_message: res.data.close_message,
          }
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  }

  const getHourList = async () => {
    try {
      setState((draft) => {
        draft.isListBusy = true;
      });
      const res = await getHourListApi(onlineOrderingID, storeID);
      if (res.success) {
        setState((draft) => {
          draft.isListBusy = false;
          draft.hourList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }

  const handleInputChange = (event, type, index) => {
    const { value, name } = event?.target;
    setState((draft) => {
      draft.hourList = [
        ...draft.hourList.map((data, i) => {
          return i === index ? { ...data, [name]: value } : data;
        }),
      ];
      draft.isHourSaveButton = false;
    });
  };

  const onUpdateWeekdays = async () => {
    try {
      setState((draft) => {
        draft.isHourBusy = true;
        draft.isHourSaveButton = true;
      });
      let payload = {
        store_hours: state.hourList.map((v) => {
          return {
            ...v,
            closing_time: moment(v.closing_time, "HH:mm:ss").format("HH:mm"),
            opening_time: moment(v.opening_time, "HH:mm:ss").format("HH:mm")
          }
        })

      };
      const res = await updateStoreHourApi(onlineOrderingID, storeID, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isHourBusy = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isHourSaveButton = false;
        draft.isHourBusy = false;
      });
    }
  };

  const handleManageModal = (state) => {
    setState((draft) => {
      draft.manageModal = state;
    });
  }

  const handleManageInput = (event) => {
    const { value, name } = event?.target;
    setState((draft) => {
      draft.manageData[name] = value;
    });
  }

  const onUpdateMangeStatus = async () => {
    try {
      setState((draft) => {
        draft.isManageBusy = true;
      });
      let payload = {
        ...state.manageData,
        close_at: moment(state.manageData.close_at, "HH:mm:ss").format("HH:mm"),
        open_at: moment(state.manageData.open_at, "HH:mm:ss").format("HH:mm")
      }
      const res = await updateManageStatusApi(onlineOrderingID, storeID, payload);
      if (res.success) {
        successMessage(res.message);
        handleManageModal(false)
        getOnlineOrderingDetails()
        setState((draft) => {
          draft.isManageBusy = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isManageBusy = false;
      });
    }
  };
  const getHolidays = async () => {
    try {
      const res = await getHolidaysInStore(storeID);
      if (res.success) {
        setState((draft) => {
          draft.holidayData.holidayList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }

  const getPublicHolidays = async () => {
    try {
      const res = await getPublicHolidaysInStore(storeID);
      if (res.success) {
        setState((draft) => {
          draft.holidayData.publicHolidayList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  }




  return {
    state,
    currentTab,
    setCurentTab,
    handleInputChange,
    isEditable,
    onUpdateWeekdays,
    handleManageModal,
    handleManageInput,
    onUpdateMangeStatus,
    onUpdateMangeStatus,
  };
};
